
let skills =[
         {  
            id:'1',
            imgSrc:"/img/c++.png",
            statement:'1.5 years of experience .'
         },

         {  
            id:'2',
            imgSrc:"/img/css.jpg",
            statement:'1 years of experience .'
         },

         {  
            id:'3',
            imgSrc:"/img/html5.png",
            statement:'1 years of experience .'
         },

         {  
            id:'4',
            imgSrc:"/img/js.png",
            statement:'1 years of experience .'
         },

         {  
            id:'5',
            imgSrc:"/img/nodejs.png",
            statement:'6 months of experience .'
         },

         {  
            id:'6',
            imgSrc:"/img/react.png",
            statement:'6 months of experience .'
         },

         {  
            id:'7',
            imgSrc:"/img/mongoDb.gif",
            statement:'6 months of experience .'
         }
        ];

   let projects = [
                   {
                     id:'8',
                     imgSrc:"/img/Dump.png",
                     statement:"A react typing Website using React , Firebase.",
                     url:"https://github.com/div328/DumbType"
                   },

                   {
                     id:'9',
                     imgSrc:"/img/login.png",
                     statement:"Hashing/Salting of Password using Node.js, crypto.",
                     url:''
                   },

                   {
                     id:'10',
                     imgSrc:"/img/simon.png",
                     statement:"A HTML,CSS,JavaScript simon game.",
                     url:''
                   }
                  ];
   
   
   let obj = {skills,projects}

export default obj ;